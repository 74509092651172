<template>
  <svg :class="props.class" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.9881 0H6.0119V4.00794H10V6.03175H6.0119V10.0397H3.9881V6.03175H0V4.00794H3.9881V0Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4'
  }
)
</script>
