import type { MfiAPI } from '@hubelia/mfiapi'
import type { UsersList } from '~/types/directus'
import type { IndexedList } from '~/types/lists'

export async function fetchUserLists(params: {
  locale: string
  userId: string
  sdk: MfiAPI
  page: number
  hitsPerPage: number
}) {
  const { locale, userId, sdk, page, hitsPerPage } = params
  return await sdk.v1.mfi.lists.getAll(userId, { locale, page, hitsPerPage }).then((res) => {
    return {
      data: res.data.data as Array<UsersList>,
      totalPages: res.data.totalPages,
      page: res.data.page
    }
  })
}

export async function fetchUserListById(params: { locale: string; listId: string; sdk: MfiAPI }) {
  const { locale, listId, sdk } = params

  return await sdk.v1.mfi.lists.get(listId, { locale }).then((res) => {
    return res.data as UsersList
  })
}

export async function fetchRandomLists(params: { locale: string; sdk: MfiAPI; limit?: number }) {
  const { locale, sdk, limit } = params

  return await sdk.v1.mfi.lists
    .listRandom(
      {
        locale,
        limit
      },
      {}
    )
    .then((res) => res.data as Array<IndexedList>)
}

export async function createUserList(params: {
  sdk: MfiAPI
  data: {
    name: string
    public: boolean
    locale?: string
  }
}) {
  const { sdk, data } = params
  return await sdk.v1.mfi.lists.create({}, data).then((res) => res.data as Array<UsersList>)
}

export async function deleteUserList(params: { sdk: MfiAPI; userListId: string }) {
  const { sdk, userListId } = params
  return await sdk.v1.mfi.lists.delete(userListId, {})
}

export async function updateUserList(
  params: { sdk: MfiAPI; userListId: string },
  data: { name?: string; public?: boolean; default_list: boolean }
) {
  const { sdk, userListId } = params
  await sdk.v1.mfi.lists.update(userListId, data)
}

export async function deleteMovieFromUserList(params: {
  listId: string
  movieId: string
  sdk: MfiAPI
}) {
  const { sdk, listId, movieId } = params
  return await sdk.v1.mfi.lists
    .listId(listId, movieId, {})
    .then((res) => res.data as Array<UsersList>)
}
export async function addMovieToUserList(params: {
  list_id: string
  movie_ids: number[]
  sdk: MfiAPI
  locale: string
}) {
  const { sdk, list_id: listId, movie_ids: movieIds, locale } = params
  return await sdk.v1.mfi.lists.addMovies({}, { list_id: listId, movie_ids: movieIds, locale })
}
