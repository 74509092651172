import { INFINITE_HITS } from '~/consts/general'
import type { UserData } from '~/types/directus'
import { fetchUserLists } from '~/helpers/query/lists'
import type { FetchQueryOptions } from '@tanstack/vue-query'
import { defaultStaleTime } from '~/consts/query'

export function useUserLists() {
  const { locale } = useI18n()
  const { data: userData } = useAuth()
  const userId = computed(() => (userData?.value?.user as UserData)?.id)

  const { sdk } = useBackendSDK()
  const userListsQueryOptions: FetchQueryOptions<Array<ReturnType<typeof fetchUserLists>>> = {
    queryKey: ['userLists', { userId, locale, INFINITE_HITS }],
    queryFn: () => {
      return fetchUserLists({
        locale: locale.value,
        userId: (userData?.value?.user as UserData)?.id,
        sdk,
        page: 1,
        hitsPerPage: INFINITE_HITS
      })
    },
    staleTime: defaultStaleTime,
    enabled: computed(() => !!userId.value)
  }
  const { data: userLists, isLoading } = useQuery(userListsQueryOptions)

  // Returns true if the user only have the default list
  const isOnlyDefaultList = computed(() =>
    userLists.value?.data?.every((_list) => _list?.default_list)
  )

  const defaultList = computed(
    () => userLists?.value?.data?.find((_list) => _list?.default_list) ?? null
  )
  const listId = computed(() => userLists?.value?.data?.filter((el) => el.default_list)[0]?.id)

  return {
    userLists,
    isLoading,
    isOnlyDefaultList,
    defaultList,
    listId
  }
}
